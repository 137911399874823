
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { useStore } from "vuex";
import MotorInsuranceAdditionFormEdit from "@/components/reusable/MotorInsuranceAdditionFormEdit.vue";

export default defineComponent({
  name: "motor-insurance-edit-addition-form",
  components: {
    Form,
    Field,
    GoBackButton,
    TotalPremiumWidget,
    MotorInsuranceAdditionFormEdit,
  },
  data() {
    return {
      viewUploadedImages: false,
      displayBreakdown: false,
      displayError: false,
      policies: [],
      covers: [],
      metadatas: [],
      policySummary: {},
      policyDetails: [],
      totalPremium: 0.0 as number,
      insurancePackage: "",
      publicId: this.$route.params.publicId as string,
      totalSumInsured: 0.0 as number,
      packageType: "",
      packageShort: "",
      motorPolicyId: "",
      cartItemPublicId: "",
      premiumCalculationRoute: "" as string,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            vehicleOwner: Yup.string()
              .required()
              .label("Vehicle owner name"),
            vehicleValue: Yup.mixed()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.mixed().required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            vehicleRegistrationNumber: Yup.string()
              .required()
              .min(2)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .required()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .required()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed().label("Vehicle license"),
          })
        )
        .strict(),
    });

    const updateCartInvoice = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (key === "vehicleImages") {
                  if (
                    typeof value === "string" &&
                    value.includes(`metadata[${x}].vehicleImages`)
                  ) {
                    continue;
                  }

                  if (Array.isArray(value)) {
                    value.forEach((itemValue, index) => {
                      formData.append(
                        `vehicleDetails[${key}][${x}][${index}]`,
                        itemValue as string | Blob
                      );
                    });
                  }
                } else if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            if (
              formData.get("vehicleDetails[vehicleLicense][0]") === "undefined"
            ) {
              formData.set("vehicleDetails[vehicleLicense][0]", "");
              // formData.delete("vehicleDetails[vehicleLicense][0]");
            }

            if (
              formData.get("vehicleDetails[vehicleLicense][1]") === "undefined"
            ) {
              formData.set("vehicleDetails[vehicleLicense][1]", "");
            }

            if (
              formData.get("vehicleDetails[vehicleLicense][2]") === "undefined"
            ) {
              formData.set("vehicleDetails[vehicleLicense][2]", "");
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.CART_ROUTE}/${values["cartItemPublicId"]}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-cart-debit-note",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Insurance Packages",
        "Motor Insurance Policy",
        "Addition",
      ]);

      MenuComponent.reinitialization();
    });

    return {
      submitButton1,
      metadataValidator,
      userType,
      updateCartInvoice,
    };
  },
  created() {
    // Get invoice information
    this.getInvoiceInformation();

    // Get overs available based on the policy business type selected i.e Private or Commercial
    // this.getCovers();

    //Set page title
    document.title =
      "Addition: Motor Insurance Edit | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getInvoiceInformation: function() {
      const router = useRouter();
      this.cartItemPublicId = this.publicId;

      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to corresponding fields
            if (data.data != "") {
              this.policySummary = data.data;
              this.metadatas = data.data.vehicleDetails;
              this.totalPremium = data.data.premium;
              this.totalSumInsured = data.data.sumInsured;
              this.packageType = data.data.businessType;
              this.motorPolicyId = data.data.policyPublicId;
              this.premiumCalculationRoute = `${variables.CART_ROUTE}/premium/addition/${data.data.policyPublicId}`;
              if (this.packageType !== undefined) {
                this.covers = data?.data?.policySummary?.packages;
                this.insurancePackage =
                  data?.data?.policySummary?.businessTypeShortName;
              }
            } else {
              this.displayError = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "insurance-packages-cart" });
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
